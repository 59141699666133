import request from '@/router/axios';
import { pvhApiBase } from "@/config/env";

export const List = (id, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetOrderPackageOperationLogs",
        method: "get",
        params: {
            "id": id,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const add = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderPackageOperationLog",
        method: "post",
        data: {
            ...row
        }
    })
}
export const addSummaryPackage = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostSummaryPackage",
        method: "post",
        data: {
            ...row
        }
    })
}
export const addPackage = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostOrderPackage",
        method: "post",
        data: {
            ...row
        }
    })
}
export const packageProgress = (packageId) => {
    return request({
        url: pvhApiBase + "Tracing/GetOrderPackageProgress",
        method: "get",
        params: {
            "PackageId": packageId
        }
    })
}
export const DownPDFPassKey = (packageId) => {
    return request({
        url: pvhApiBase + "Tracing/GetDownPDFPassKey",
        method: "get",
        params: {
            "PackageId": packageId
        }
    })
}
export const PdfGenerationList = (page,pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetOrderPackages",
        method: "get",
        params: {
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const IsHavePackage = (packageId) => {
    return request({
        url: pvhApiBase + "Tracing/GetIsHaveOrderPackageUrl",
        method: "get",
        params: {
            "PackageId": packageId
        }
    })
}
//export const encryptionPdfPackage = (PackageId, operationType, token) => {
//    return request({
//        url: pvhApiBase + "Tracing/GetEncryptionPdfPackage",
//        method: "get",
//        responseType: 'blob',
//        params: {
//            "PackageId": PackageId,
//            "operationType": operationType,
//            "token": token,
//        }
//    })
//}

export const GetOrderPackage = (orderId) => {
    return request({
        url: pvhApiBase + "Tracing/GetOrderPackageByOrderId",
        method: "get",
        params: {
            "orderId": orderId
        }
    })
}

export const GetSummaryPackage = (orderId) => {
    return request({
        url: pvhApiBase + "Tracing/GetSummaryPackageByOrderId",
        method: "get",
        params: {
            "orderId": orderId
        }
    })
}
export const pdfPreviewFileStream = (packageUrl) => {
    return request({
        url: pvhApiBase + "Tracing/GetPdfPreviewFileStream",
        method: "get",
        responseType: 'blob',
        params: {
            "packageUrl": packageUrl
        }
    })
}